import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faRemove} from "@fortawesome/free-solid-svg-icons";

function Invite4({ prev, next }) {
    const { formData, updateFormData } = useFormContext();

    // Updated initialValues: Added numTeams field
    const initialValues = {
        regions: formData.regions.map((region) => ({
            regionName: region.regionName,
            numBrokers: region.numBrokers,
            brokers: region.brokers && region.brokers.length > 0
                ? region.brokers.map((broker) => ({
                    brokerName: broker.brokerName || '',
                    brokerageName: broker.brokerageName || '',
                    email: broker.email || '',
                    numAssociates: broker.numAssociates || '',
                    numTeams: broker.numTeams || '',
                    // Initialize agents based on numAssociates
                    agents: broker.agents && broker.agents.length > 0
                        ? broker.agents.map((agent) => ({
                            agentName: agent.agentName || '',
                            agentEmail: agent.agentEmail || '',
                        }))
                        : broker.numAssociates && broker.numAssociates > 0
                            ? Array.from({ length: parseInt(broker.numAssociates, 10) }).map(() => ({
                                agentName: '',
                                agentEmail: '',
                            }))
                            : [],
                    teams: broker.teams && broker.teams.length > 0
                        ? broker.teams.map((team) => ({
                            teamName: team.teamName || '',
                            teamLeaderName: team.teamLeaderName || '',
                            email: team.email || '',
                            numAssociates: team.numAssociates || '',
                            teamMembers: team.teamMembers && team.teamMembers.length > 0
                                ? team.teamMembers.map((member) => ({
                                    memberName: member.memberName || '',
                                    memberEmail: member.memberEmail || '',
                                }))
                                : (team.numAssociates > 0
                                    ? Array.from({ length: parseInt(team.numAssociates, 10) }).map(() => ({
                                        memberName: '',
                                        memberEmail: '',
                                    }))
                                    : []),
                        }))
                        : broker.numTeams > 0
                            ? Array.from({ length: parseInt(broker.numTeams, 10) }).map(() => ({
                                teamName: '',
                                teamLeaderName: '',
                                email: '',
                                numAssociates: '',
                            }))
                            : [],
                }))
                : region.numBrokers > 0
                    ? Array.from({ length: parseInt(region.numBrokers, 10) }).map(() => ({
                        brokerName: '',
                        brokerageName: '',
                        email: '',
                        numAssociates: '',
                        numTeams: '',
                        agents: [], // Ensure agents array is initialized
                    }))
                    : [],
        })),
    };


    // Updated validationSchema: Added validation for numTeams
    const validationSchema = Yup.object({
        regions: Yup.array().of(
            Yup.object({
                regionName: Yup.string().required('Region name is required'),
                brokers: Yup.array().of(
                    Yup.object({
                        brokerName: Yup.string().required('Broker name is required'),
                        brokerageName: Yup.string().required('Brokerage name is required'),
                        email: Yup.string()
                            .email('Invalid email format')
                            .required('Email is required'),

                    })
                ),
            })
        ),
    });

    return (
        <div className="container mt-5 text-start">
            <h2>Brokerage Name and its Broker and Number of Associate/Agents</h2>
            <p className="text-start">
                Provide the Brokerage Name, its Broker, Email, and the Number of Associates/Agents
            </p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    updateFormData({ ...formData, regions: values.regions });
                    next();
                }}
            >
                {({ values }) => (
                    <Form className="w-100">
                        {values.regions.map((region, regionIndex) => (
                            <div key={regionIndex} className="card p-3 mb-4">
                                <h4>{region.regionName}</h4>
                                <FieldArray name={`regions.${regionIndex}.brokers`}>
                                    {() => (
                                        <>
                                            {region.brokers.map((broker, brokerIndex) => (
                                                <div key={brokerIndex} className="row mb-3">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.brokerageName`}>
                                                                Brokerage Name
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id={`regions.${regionIndex}.brokers.${brokerIndex}.brokerageName`}
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.brokerageName`}
                                                                placeholder="Enter brokerage name"
                                                            />
                                                            <ErrorMessage
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.brokerageName`}
                                                                component="div"
                                                                className="text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.brokerName`}>
                                                                Broker Name
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                id={`regions.${regionIndex}.brokers.${brokerIndex}.brokerName`}
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.brokerName`}
                                                                placeholder="Enter broker name"
                                                            />
                                                            <ErrorMessage
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.brokerName`}
                                                                component="div"
                                                                className="text-danger"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.email`}>
                                                                Email
                                                            </label>
                                                            <Field
                                                                type="email"
                                                                className="form-control"
                                                                id={`regions.${regionIndex}.brokers.${brokerIndex}.email`}
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.email`}
                                                                placeholder="Enter email"
                                                            />
                                                            <ErrorMessage
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.email`}
                                                                component="div"
                                                                className="text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.numTeams`}>
                                                                Number of Teams
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                className="form-control"
                                                                id={`regions.${regionIndex}.brokers.${brokerIndex}.numTeams`}
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.numTeams`}
                                                                placeholder="0"
                                                            />
                                                            <ErrorMessage
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.numTeams`}
                                                                component="div"
                                                                className="text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.numAssociates`}>
                                                                Number of Associate/Agents
                                                            </label>
                                                            <Field
                                                                type="number"
                                                                className="form-control"
                                                                id={`regions.${regionIndex}.brokers.${brokerIndex}.numAssociates`}
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.numAssociates`}
                                                                placeholder="0"
                                                            />
                                                            <ErrorMessage
                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.numAssociates`}
                                                                component="div"
                                                                className="text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        ))}
                        <hr/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Invite4;
