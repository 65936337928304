import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Checkbox, FormControlLabel, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faRemove } from '@fortawesome/free-solid-svg-icons';
import { marketingStaffArray } from "../constants";

const MarketingStaff = ({ prev, next, updateSkipSteps }) => {
    const { formData, updateFormData } = useFormContext();

    const validationSchema = Yup.object({
        marketingStaff: Yup.array()
            .min(1, 'Please select at least one category')
            .required('This field is required'),
    });

    const marketingStaff = marketingStaffArray[formData.industryType] || marketingStaffArray.default;

    const handleSelectAll = (setFieldValue, values) => {
        const newSelection = values.marketingStaff.length === marketingStaff.length ? [] : marketingStaff.map((category) => category.value);
        setFieldValue('marketingStaff', newSelection);
        updateFormData({ ...formData, marketingStaff: newSelection });
    };

    return (
        <div className="container align-items-center mt-5 mb-5 fade-in">
            <div className="header">
                <h2 className="text-start mb-2">Select Your Marketing Management and Sales & Marketing Executives to Invite</h2>
                <p className="text-start mb-4">
                    You can select who has access to content, data, and activity based on the level of oversight you prescribe.<br/>
                    The top representative has full account visibility, Regionals oversee locations within their region,<br/>
                    and Executive Directors/Administrators manage their specific properties.<br/>
                    Sales and marketing executives handle only the referrals and data related to their property and activity.
                </p>
            </div>
            <Formik
                initialValues={{
                    marketingStaff: formData.marketingStaff || [],
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => next()}
            >
                {({ values, setFieldValue }) => (
                    <Form className="w-100">
                        <div className="row">
                            <div className="col-md-12 mb-3 text-start">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.marketingStaff.length === marketingStaff.length}
                                            onChange={() => handleSelectAll(setFieldValue, values)}
                                        />
                                    }
                                    label="Select All"
                                />
                                <div className="checkbox-group">
                                    {marketingStaff.map((category) => (
                                        <FormControlLabel
                                            key={category.value}
                                            control={
                                                <Checkbox
                                                    name="marketingStaff"
                                                    value={category.value}
                                                    checked={values.marketingStaff.includes(category.value)}
                                                    onChange={() => {
                                                        const updatedSelection = values.marketingStaff.includes(category.value)
                                                            ? values.marketingStaff.filter((item) => item !== category.value)
                                                            : [...values.marketingStaff, category.value];
                                                        setFieldValue('marketingStaff', updatedSelection);
                                                        updateFormData({ ...formData, marketingStaff: updatedSelection });
                                                    }}
                                                />
                                            }
                                            label={category.label}
                                        />
                                    ))}
                                </div>
                                <ErrorMessage name="marketingStaff" component="div" className="text-danger" />
                            </div>
                        </div>

                        <hr style={{ margin: '20px 0' }} />

                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} className="icon" />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default MarketingStaff;
