import React, { useState } from "react";
import { useFormContext } from "../context/FormContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button, Box, CircularProgress, Typography, Chip, Avatar} from "@mui/material";
import { API_BASE_URL } from "../config";

const ReviewAndSubmit = () => {
    const { formData } = useFormContext();
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false); // Manage success state
    const companyLogo = localStorage.getItem("companyLogo");
    const localCompanyLogo = localStorage.getItem("localCompanyLogo");
    const formDataWithCompanyData = {
        ...formData,
        companyLogo,
        localCompanyLogo
    };

    const handleSubmit = async () => {
        setLoading(true);
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${API_BASE_URL}/save-user-company`, formDataWithCompanyData);
            if (response.status === 200) {
                localStorage.removeItem('companyLogo');
                localStorage.removeItem('formData');
                localStorage.removeItem('currentStep');
                localStorage.removeItem('localCompanyLogo');

                setIsSuccess(true); // Set success state
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred. Please try again.");
            setIsSubmitting(false);
        } finally {
            setLoading(false);
        }
    };

    if (isSubmitting) {
        if (isSuccess) {
            // Success screen
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 9999,
                        textAlign: 'center',
                    }}
                >
                    <FontAwesomeIcon icon={faCheckCircle} size="5x" color="green" />
                    <Typography variant="h4" color="green" mt={2}>
                        Your request has been completed!
                    </Typography>
                    <Typography variant="body1" mt={1} mb={4}>
                        Thank you for submitting your details. We will process your request shortly.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.location.href = "https://yourseniorteam.com/login"}
                    >
                        Go to Login
                    </Button>
                </Box>
            );
        }

        // Loading screen
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999,
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                <CircularProgress color="error" size={50} />
                <Typography color="black" variant="h6" mt={2}>
                    Submitting...
                </Typography>
                <Typography color="black" variant="body1">
                    Please wait while we are saving your information. We will be sending an invite email to all the users.
                </Typography>
            </Box>
        );
    }

    return (
        <div className="container text-start my-5 p-4">

            <div className="text-start mb-4">
                <div className="header">
                    <h2>Review Provided Details</h2>
                    <p>Please review all provided details below before submitting the form</p>
                </div>
                {companyLogo && (
                    <img
                        src={companyLogo}
                        alt="Company Logo"
                        className="img-fluid rounded mb-3"
                        style={{ maxHeight: "100px" }}
                    />
                )}
            </div>

            {/* Personal Information */}
            <div className="mb-4">
                <h4>Personal Information</h4>
                <hr/>
                <p><strong>First Name:</strong> {formData.firstName || "N/A"}</p>
                <p><strong>Last Name:</strong> {formData.lastName || "N/A"}</p>
                <p><strong>Email:</strong> {formData.email || "N/A"}</p>
            </div>

            {/* Company Information */}
            {formData.corporationName && (
                <div className="mb-4">
                    <h4>Company Information (Parent Enitity)</h4>
                    <hr />
                    <p><strong>Corporation Name:</strong> {formData.corporationName}</p>
                    <p><strong>Office Address:</strong> {formData.officeAddress || "N/A"}</p>
                    <p><strong>Phone Number:</strong> {formData.phoneNumber || "N/A"}</p>
                    <p><strong>Fax Number:</strong> {formData.faxNumber || "N/A"}</p>
                </div>
            )}

            {formData.localCorporationName && (
                <div className="mb-4">
                    <h4>Company Information ( Local Entity)</h4>
                    <hr />
                    <p><strong>Corporation Name:</strong>{formData.localCorporationName || "N/A"} </p>
                    <p><strong>Office Address:</strong> { formData?.localFormattedAddress?.formattedData?.label || formData?.localOfficeAddress}</p>
                    <p><strong>Phone Number:</strong> {formData.localPhoneNumber || "N/A"}</p>
                    <p><strong>Fax Number:</strong> {formData.localFaxNumber || "N/A"}</p>
                </div>
            )}

            {/* Selected Company */}
            {formData.selectedCompany && (
                <div className="mb-4 ">
                    <h4>Selected Company Parent Entitiy</h4>
                    <hr/>
                    <div className="d-flex align-items-center mb-3">
                        <Avatar
                            src={formData.selectedCompany.logo || "/placeholder.png"}
                            alt="Company Logo"
                            sx={{width: 56, height: 56}}
                        />
                        <div className="ms-3">
                            <h5>{formData.selectedCompany.name}</h5>
                            <p>{formData.selectedCompany.category}</p>
                        </div>
                    </div>
                    <div className="company-info">
                        <p>
                            <strong>Address:</strong> {formData.selectedCompany.address}, {formData.selectedCompany.city}, {formData.selectedCompany.state}, {formData.selectedCompany.country}, {formData.selectedCompany.zipcode}
                        </p>
                        <p><strong>Email:</strong> {formData.selectedCompany.email}</p>
                        <p><strong>Phone:</strong> {formData.selectedCompany.tel}</p>
                    </div>
                </div>
            )}

            {formData.localSelectedCompany && (
                <div className="mb-4 ">
                    <h4>Selected Company Local Entity </h4>
                    <hr/>
                    <div className="d-flex align-items-center mb-3">
                        <Avatar
                            src={formData.localSelectedCompany.logo || "/placeholder.png"}
                            alt="Company Logo"
                            sx={{width: 56, height: 56}}
                        />
                        <div className="ms-3">
                            <h5>{formData.localSelectedCompany.name}</h5>
                            <p>{formData.localSelectedCompany.category}</p>
                        </div>
                    </div>
                    <div className="company-info">
                        <p>
                            <strong>Address:</strong> {formData.localSelectedCompany.address}, {formData.localSelectedCompany.city}, {formData.localSelectedCompany.state}, {formData.localSelectedCompany.country}, {formData.localSelectedCompany.zipcode}
                        </p>
                        <p><strong>Email:</strong> {formData.localSelectedCompany.email}</p>
                        <p><strong>Phone:</strong> {formData.localSelectedCompany.tel}</p>
                    </div>
                </div>
            )}

            {formData.regions && formData.regions.length > 0 && (
                <div className="mb-4">
                    <h4>Brokers, Agents, and Teams</h4>
                    <hr />
                    {/* Regions */}
                    {formData.regions.map((region, regionIndex) => (
                        <div key={regionIndex} className="mb-4">
                            {/* Region Header */}
                            <div className="bg-light p-2 rounded">
                                <h5 className="mb-0">{region.regionName}</h5>
                            </div>
                            <hr />

                            {/* Brokers */}
                            {region.brokers.map((broker, brokerIndex) => (
                                <div key={brokerIndex} className="mb-3">
                                    <div className="border-left-0 border-right-0 border-primary p-2">
                                        <div className="row mb-2">
                                            <div className="col-md-3">
                                                <strong>Broker Name:</strong> {broker.brokerName || "N/A"}
                                            </div>
                                            <div className="col-md-3">
                                                <strong>Brokerage Name:</strong> {broker.brokerageName || "N/A"}
                                            </div>
                                            <div className="col-md-3">
                                                <strong>Broker Email:</strong> {broker.email || "N/A"}
                                            </div>
                                        </div>


                                        {/* Teams */}
                                        {broker.teams && broker.teams.length > 0 && (
                                            <div className="ml-4 mt-3 p-2 border border-info rounded">
                                                <h6>Teams</h6>
                                                <div className="d-none d-md-flex row font-weight-bold text-uppercase mb-2">
                                                    <div className="col-md-4">Team Name</div>
                                                    <div className="col-md-4">Team Leader Name</div>
                                                    <div className="col-md-4">Team Email</div>
                                                </div>
                                                {broker.teams.map((team, teamIndex) => (
                                                    <div key={teamIndex} className="row mb-2">
                                                        <div className="col-md-4">{team.teamName || "N/A"}</div>
                                                        <div className="col-md-4">{team.teamLeaderName || "N/A"}</div>
                                                        <div className="col-md-4">{team.email || "N/A"}</div>

                                                        {/* Team Members */}
                                                        {team.teamMembers && team.teamMembers.length > 0 && (
                                                            <div className="ml-4 mt-3 p-2 border border-success rounded">
                                                                <h6>Team Members</h6>
                                                                <div className="d-none d-md-flex row font-weight-bold text-uppercase mb-2">
                                                                    <div className="col-md-6">Member Name</div>
                                                                    <div className="col-md-6">Member Email</div>
                                                                </div>
                                                                {team.teamMembers.map((member, memberIndex) => (
                                                                    <div key={memberIndex} className="row mb-2">
                                                                        <div className="col-md-6">{member.memberName || "N/A"}</div>
                                                                        <div className="col-md-6">{member.memberEmail || "N/A"}</div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {/* Agents */}
                                        {broker.agents && broker.agents.length > 0 && (
                                            <div className="ml-4 mt-3 p-2 border border-secondary rounded">
                                                <h6>Agents</h6>
                                                <div className="d-none d-md-flex row font-weight-bold text-uppercase mb-2">
                                                    <div className="col-md-6">Agent Name</div>
                                                    <div className="col-md-6">Agent Email</div>
                                                </div>
                                                {broker.agents.map((agent, agentIndex) => (
                                                    <div key={agentIndex} className="row mb-2">
                                                        <div className="col-md-6">{agent.agentName || "N/A"}</div>
                                                        <div className="col-md-6">{agent.agentEmail || "N/A"}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}


                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}

            {/* Sales Team */}
            {formData.salesTeams && formData.salesTeams.length > 0 && (
                <div className="mb-4">
                    <h4>Regional Sales Administrator /Directors or Managers</h4>
                    <hr/>
                    {/* Headings for larger screens */}
                    <div className="d-none d-md-flex row font-weight-bold text-uppercase mb-2">
                        <div className="col-md-4">Location Name</div>
                        <div className="col-md-4">Contact Name</div>
                        <div className="col-md-4">Contact Email</div>
                    </div>
                    {/* Data Rows */}
                    {formData.salesTeams.map((team, index) => (
                        <div key={index} className="row mb-3">
                            <div className="col-md-4 d-none d-md-block">{team.locationName || "N/A"}</div>
                            <div className="col-md-4 d-none d-md-block">{team.contactName || "N/A"}</div>
                            <div className="col-md-4 d-none d-md-block">{team.contactEmail || "N/A"}</div>

                            {/* For smaller screens */}
                            <div className="col-12 d-block d-md-none">
                                <strong>Location Name:</strong> {team.locationName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Name:</strong> {team.contactName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Email:</strong> {team.contactEmail || "N/A"}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Child Companies */}
            {formData.childCompanies && formData.childCompanies.length > 0 && (
                <div className="mb-4">
                    <h4>Marketing Sales Executive</h4>
                    <hr />
                    {/* Headings for larger screens */}
                    <div className="d-none d-md-flex row font-weight-bold text-uppercase mb-2">
                        <div className="col-md-4">Location Name</div>
                        <div className="col-md-4">Contact Name</div>
                        <div className="col-md-4">Contact Email</div>
                    </div>
                    {/* Data Rows */}
                    {formData.childCompanies.map((team, index) => (
                        <div key={index} className="row mb-3">
                            {/* For larger screens */}
                            <div className="col-md-4 d-none d-md-block">{team.locationName || "N/A"}</div>
                            <div className="col-md-4 d-none d-md-block">{team.contactName || "N/A"}</div>
                            <div className="col-md-4 d-none d-md-block">{team.contactEmail || "N/A"}</div>

                            {/* For smaller screens */}
                            <div className="col-12 d-block d-md-none">
                                <strong>Location Name:</strong> {team.locationName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Name:</strong> {team.contactName || "N/A"}
                            </div>
                            <div className="col-12 d-block d-md-none">
                                <strong>Contact Email:</strong> {team.contactEmail || "N/A"}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div className="mb-4">
                <Typography variant="h6" gutterBottom>
                    Additional Information
                </Typography>
                <hr />
                <Typography variant="body1" component="p">
                    <strong>Industry Type:</strong> {formData.industryType || "N/A"}
                </Typography>
                <Typography variant="body1" component="p" mt={2}>
                    <strong>What We Offer:</strong>
                    <Box mt={1}>
                        {formData.whatWeOffer && formData.whatWeOffer.length ? (
                            formData.whatWeOffer.map((offer, index) => (
                                <Chip
                                    key={index}
                                    label={offer.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                                    color="primary"
                                    style={{ marginRight: '8px', marginBottom: '8px' }}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                N/A
                            </Typography>
                        )}
                    </Box>
                </Typography>
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                    startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                >
                    {loading ? "Submitting..." : "Submit Form"}
                </Button>
            </div>
        </div>
    );
};

export default ReviewAndSubmit;
