import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button, Avatar, Badge } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faArrowRight,
    faTimes,
    faPlus,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CompanySearchSelect from './sections/CompanySearchSelect';
import AddressAutocomplete from "./sections/AddressAutocomplete";
import axios from "axios";
import {API_BASE_URL} from "../config";

const LocalCompanyForm = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();
    const [isAddingCompany, setIsAddingCompany] = useState(false);
    const [isNameTaken, setIsNameTaken] = useState(false);

    const validationSchema = Yup.object({
        localCorporationName: Yup.string()
            .required('Regional name is required'),
        localOfficeAddress: Yup.string()
            .required('Office address is required'),
        localCity: Yup.string()
            .required('City is required'),
        localState: Yup.string()
            .required('State is required'),
        localCountry: Yup.string()
            .required('Country is required'),
        localZipcode: Yup.string()
            .required('Zipcode is required'),
        localPhoneNumber: Yup.string()
            .required('Phone number is required'),
        localFaxNumber: Yup.string()
            .required('Fax number is required'),
    });

    const checkCorporationName = async (name) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/search-company-name`, { params: { name } });
            setIsNameTaken(response.data.nameExists);
        } catch (error) {
            console.error("Error checking corporation name:", error);
            setIsNameTaken(false);
        }
    };

    const handleAddressChange = (selectedAddress) => {
        const { label,  ...formattedData } = selectedAddress;
        const  sData = formattedData.formattedData;

        updateFormData({
            localOfficeAddress : sData.label ?? "",
            localCity: sData.city,
            localState: sData.state,
            localZipcode: sData.zipcode,
            localCountry: sData.country,
            localFormattedAddress: { ...formattedData },
        });
    };

    const handleClearForm = (resetForm) => {
        resetForm();
        updateFormData({
            localSelectedCompany: null,
            localCorporationName: '',
            localOfficeAddress: '',
            localFormattedAddress : [],
            localPhoneNumber: '',
            localFaxNumber: '',
            localCity: '',
            localState: '',
            localCountry: '',
        });
        setIsAddingCompany(false);
    };

    return (
        <div className="container mt-5 mb-5 text-start fade-in">
            <div className="header">
                <h2>Add Your Local  Office/Region Information</h2>
                <p>This is your entity where you work</p>
            </div>

            {!isAddingCompany && !formData.localSelectedCompany && !formData.localRegisteredCompanyId && <CompanySearchSelect localCompany={true} clickToRegister={() => setIsAddingCompany(true)} />}
            {!isAddingCompany && !formData.localSelectedCompany && !formData.localRegisteredCompanyId && (
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={() => setIsAddingCompany(true)}
                    className="mb-3">
                    If your company is not listed above! Please click here to register it!
                </Button>
            )}

            <Formik
                initialValues={{
                    ...formData
                }}
                enableReinitialize={true}
                onSubmit={(values) => {
                    updateFormData(values);
                    if (!isNameTaken) {
                        next();
                    }
                }}>
                {({ handleChange, handleBlur, values, resetForm }) => (
                    <Form className="text-start">
                        {isAddingCompany || formData.localCorporationName !== "" ? (
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="company-details">
                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <Avatar src="/placeholder.png" alt="Company Logo"
                                                        sx={{width: 56, height: 56}}/>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="localCorporationName">
                                                        Corporation/Company Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="localCorporationName"
                                                        id="localCorporationName"
                                                        className="form-control"
                                                        placeholder="Enter Name of Corporation"
                                                        value={values.localCorporationName}
                                                        onChange={(e) => {
                                                            if (formData.registeredCompanyId) return;
                                                            const name = e.target.value;
                                                            handleChange(e);
                                                            updateFormData({ ...formData, localCorporationName: name });
                                                            checkCorporationName(name);
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    {isNameTaken && (
                                                                <div className="text-danger mt-2">
                                                                    This corporation name is already taken. Please
                                                                    choose another.
                                                                </div>
                                                            )}
                                                            <ErrorMessage
                                                                name="localCorporationName"
                                                                component="div"
                                                                className="text-danger mt-2"
                                                            />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="localOfficeAddress">
                                                        Office Address <span className="text-danger">*</span>
                                                    </label>
                                                    <AddressAutocomplete
                                                        name="localOfficeAddress"
                                                        value={values?.localFormattedAddress?.formattedData?.label || values?.localOfficeAddress}
                                                        onChange={handleAddressChange}
                                                    />
                                                    <ErrorMessage name="localOfficeAddress" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="localCity">
                                                        City <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="localCity"
                                                        id="localCity"
                                                        className="form-control"
                                                        value={values?.localCity}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                localCity: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="localCity" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="localState">
                                                        State <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="localState"
                                                        id="localState"
                                                        className="form-control"
                                                        value={values?.localState}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                localState: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="localState" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="localCountry">
                                                        Country <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="localCountry"
                                                        id="localCountry"
                                                        className="form-control"
                                                        value={values?.localCountry}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                localCountry: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="localCountry" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="localZipcode">
                                                        Zipcode <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="localZipcode"
                                                        id="localZipcode"
                                                        className="form-control"
                                                        value={values?.localZipcode}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                localZipcode: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="localZipcode" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="localPhoneNumber">
                                                        PhoneNumber <span className="text-danger"></span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="localPhoneNumber"
                                                        id="localPhoneNumber"
                                                        className="form-control"
                                                        value={values?.localPhoneNumber}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                localPhoneNumber: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="localPhoneNumber" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="localFaxNumber">
                                                        Fax Number <span className="text-danger"></span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="localFaxNumber"
                                                        id="localFaxNumber"
                                                        className="form-control"
                                                        value={values?.localFaxNumber}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                localFaxNumber: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="localFaxNumber" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleClearForm(resetForm)}
                                            className="mb-3 ms-3"
                                            style={{width: 'auto'}}>
                                            Clear Form
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            formData.localSelectedCompany && (
                                <div className="selected-company-details card p-5">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4>Selected Company Details</h4>
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{cursor: 'pointer', color: 'red'}}
                                            title="Remove selected company"
                                            onClick={() => updateFormData({...formData, localSelectedCompany: null})}
                                        />
                                    </div>
                                    <div className="company-card">
                                        <div className="d-flex align-items-center mb-3">
                                            <Avatar
                                                src={formData.localSelectedCompany.logo || "/placeholder.png"}
                                                alt="Company Logo"
                                                sx={{width: 56, height: 56}}
                                            />
                                            <div className="ms-3">
                                                <h5>{formData.localSelectedCompany.name}</h5>
                                                <p>{formData.localSelectedCompany.category}</p>
                                            </div>
                                        </div>
                                        <div className="company-info">
                                            <p>
                                                <strong>Address:</strong> {formData.localSelectedCompany.address}, {formData.localSelectedCompany.city}, {formData.localSelectedCompany.state}, {formData.localSelectedCompany.country}, {formData.localSelectedCompany.zipcode}
                                            </p>
                                            <p><strong>Email:</strong> {formData.localSelectedCompany.email}</p>
                                            <p><strong>Phone:</strong> {formData.localSelectedCompany.tel}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}

                        <hr style={{margin: '10px 0'}}/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
                                disabled={!formData.localSelectedCompany && !formData.localCorporationName}>
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
export default LocalCompanyForm;
