import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button, Avatar, Badge } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faArrowRight,
    faTimes,
    faPlus,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CompanySearchSelect from './sections/CompanySearchSelect';
import AddressAutocomplete from "./sections/AddressAutocomplete";
import axios from "axios";
import {API_BASE_URL} from "../config";

const CorporateForm = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();
    const [isAddingCompany, setIsAddingCompany] = useState(false);
    const [isNameTaken, setIsNameTaken] = useState(false);

    const validationSchema = Yup.object({
        corporationName: Yup.string()
            .required('Corporation name is required'),
        officeAddress: Yup.string()
            .required('Office address is required'),
        city: Yup.string()
            .required('City is required'),
        state: Yup.string()
            .required('State is required'),
        country: Yup.string()
            .required('Country is required'),
        zipcode: Yup.string()
            .required('Zipcode is required'),
        phoneNumber: Yup.string()
            .required('Phone number is required'),
        faxNumber: Yup.string()
            .required('Fax number is required'),
    });


    const checkCorporationName = async (name) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/search-company-name`, { params: { name } });
            setIsNameTaken(response.data.nameExists);

        } catch (error) {
            console.error("Error checking corporation name:", error);
            setIsNameTaken(false);
        }
    };

    const handleAddressChange = (selectedAddress) => {
        const { label,  ...formattedData } = selectedAddress;
        const  sData = formattedData.formattedData;

        updateFormData({
            officeAddress: sData.address ?? sData.label ?? "",
            city: sData.city,
            state: sData.state,
            zipcode: sData.zipcode,
            country: sData.country,
            formattedAddress: { ...formattedData },
        });
    };

    const handleClearForm = (resetForm) => {
        resetForm();
        updateFormData({
            selectedCompany: null,
            corporationName: '',
            officeAddress: '',
            formattedAddress : [],
            phoneNumber: '',
            faxNumber: '',
            city: '',
            state: '',
            country: '',
        });
        setIsAddingCompany(false);
    };

    return (
        <div className="container mt-5 mb-5 text-start fade-in">
            <div className="header">
                <h2>Add Your Corporate/Company Information</h2>
                <p>This is for the entity and its central location.</p>
            </div>

            {!isAddingCompany && !formData.selectedCompany && !formData.registeredCompanyId && <CompanySearchSelect  clickToRegister={() => setIsAddingCompany(true)}  />}
            {!isAddingCompany && !formData.selectedCompany && !formData.registeredCompanyId && (
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={() => setIsAddingCompany(true)}
                    className="mb-3">
                    If your company is not listed above! Please click here to register it!
                </Button>
            )}

            <Formik
                initialValues={{
                    ...formData
                }}
                enableReinitialize={true}
                onSubmit={(values) => {
                    updateFormData(values);
                    if (!isNameTaken) {
                        next();
                    }
                }}>
                {({ handleChange, handleBlur, values, resetForm }) => (
                    <Form className="text-start">
                        {isAddingCompany || formData.corporationName !== "" ? (
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="company-details">
                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <Avatar src="/placeholder.png" alt="Company Logo"
                                                        sx={{width: 56, height: 56}}/>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="corporationName">
                                                        Corporation/Company Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="corporationName"
                                                        id="corporationName"
                                                        className="form-control"
                                                        placeholder="Enter Name of Corporation"
                                                        value={values.corporationName}
                                                        onChange={(e) => {
                                                            if (formData.registeredCompanyId) return;
                                                            const name = e.target.value;
                                                            handleChange(e);
                                                            updateFormData({ ...formData, corporationName: name });
                                                            checkCorporationName(name);
                                                        }}
                                                        onBlur={handleBlur}
                                                        disabled={formData.registeredCompanyId} // Disable input if registeredId is null
                                                    />
                                                    {formData.registeredCompanyId ? (
                                                        <div className="text-muted mt-2">
                                                            You cannot edit the Corporation Name
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {isNameTaken && (
                                                                <div className="text-danger mt-2">
                                                                    This corporation name is already taken. Please
                                                                    choose another.
                                                                </div>
                                                            )}
                                                            <ErrorMessage
                                                                name="corporationName"
                                                                component="div"
                                                                className="text-danger mt-2"
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="officeAddress">
                                                        Office Address <span className="text-danger">*</span>
                                                    </label>
                                                    <AddressAutocomplete
                                                        name="officeAddress"
                                                        value={values.officeAddress}
                                                        onChange={handleAddressChange}
                                                    />
                                                    <ErrorMessage name="officeAddress" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="city">
                                                        City <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        className="form-control"
                                                        value={values?.city}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                city: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="city" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="state">
                                                        State <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="state"
                                                        id="state"
                                                        className="form-control"
                                                        value={values?.state}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                state: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="state" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="country">
                                                        Country <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="country"
                                                        id="country"
                                                        className="form-control"
                                                        value={values?.country}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                country: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="country" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="zipcode">
                                                        Zipcode <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="zipcode"
                                                        id="zipcode"
                                                        className="form-control"
                                                        value={values?.zipcode}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                zipcode: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="zipcode" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="phoneNumber">
                                                        PhoneNumber <span className="text-danger"></span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="phoneNumber"
                                                        id="phoneNumber"
                                                        className="form-control"
                                                        value={values?.phoneNumber}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                phoneNumber: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="phoneNumber" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="faxNumber">
                                                        Fax Number <span className="text-danger"></span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="faxNumber"
                                                        id="faxNumber"
                                                        className="form-control"
                                                        value={values?.faxNumber}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            updateFormData((prevData) => ({
                                                                ...prevData,
                                                                faxNumber: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    <ErrorMessage name="faxNumber" component="div" className="text-danger mt-2"/>
                                                </div>
                                            </div>
                                        </div>

                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleClearForm(resetForm)}
                                            className="mb-3 ms-3"
                                            style={{width: 'auto'}}>
                                            Clear Form
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            formData.selectedCompany && (
                                <div className="selected-company-details card p-5">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h4>Selected Company Details</h4>
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{cursor: 'pointer', color: 'red'}}
                                            title="Remove selected company"
                                            onClick={() => updateFormData({...formData, selectedCompany: null})}
                                        />
                                    </div>
                                    <div className="company-card">
                                        <div className="d-flex align-items-center mb-3">
                                            <Avatar
                                                src={formData.selectedCompany.logo || "/placeholder.png"}
                                                alt="Company Logo"
                                                sx={{width: 56, height: 56}}
                                            />
                                            <div className="ms-3">
                                                <h5>{formData.selectedCompany.name}</h5>
                                                <p>{formData.selectedCompany.category}</p>
                                            </div>
                                        </div>
                                        <div className="company-info">
                                            <p>
                                                <strong>Address:</strong> {formData.selectedCompany.address}, {formData.selectedCompany.city}, {formData.selectedCompany.state}, {formData.selectedCompany.country}, {formData.selectedCompany.zipcode}
                                            </p>
                                            <p><strong>Email:</strong> {formData.selectedCompany.email}</p>
                                            <p><strong>Phone:</strong> {formData.selectedCompany.tel}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}

                        <hr style={{margin: '10px 0'}}/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
                                disabled={!formData.selectedCompany && !formData.corporationName}>
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CorporateForm;
