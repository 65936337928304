import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Typography, Avatar, Button } from "@mui/material";
import axios from 'axios';
import { useFormContext } from '../../context/FormContext';
import { API_BASE_URL } from '../../config';

const CompanySearchSelect = ({ localCompany , clickToRegister }) => {
    const { formData, updateFormData } = useFormContext();
    const [companies, setCompanies] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const fetchCompanies = async (query) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/search-companies`, {
                params: {
                    q: query,
                    userpost: formData.userType,
                    industryType: formData.industryType
                }
            });

            if (formData && Object.keys(formData).length > 0) {
                if (localCompany) {
                    setCompanies(response.data.filter(company => company.company?.category === "Local"));
                } else {
                    setCompanies(response.data.filter(company => company.company?.category !== "Local"));
                }
            }
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    useEffect(() => {
        const corporationField = localCompany ? formData.localCorporationName : formData.corporationName;
        if (corporationField) {
            fetchCompanies(corporationField);
        }
    }, [localCompany, formData.localCorporationName, formData.corporationName]);

    const handleCompanySelect = (event, newValue) => {
        if (newValue) {
            const updatedData = localCompany
                ? {
                    localCorporationName: '',
                    localOfficeAddress: '',
                    localPhoneNumber: '',
                    localFaxNumber: '',
                    localCompanyId: null,
                    localSelectedCompany: newValue.company || null,
                }
                : {
                    corporationName: '',
                    officeAddress: '',
                    phoneNumber: '',
                    faxNumber: '',
                    companyId: null,
                    selectedCompany: newValue.company || null,
                };

            updateFormData(updatedData);
        }
    };

    return (
        <div className="form-group col-md-7">
            <label htmlFor="companySearch">Search for your company</label>
            <Autocomplete
                id="companySearch"
                options={companies}
                getOptionLabel={(option) => option.companyname}
                onInputChange={(event, value) => {
                    setSearchQuery(value);
                    fetchCompanies(value);
                }}
                onChange={handleCompanySelect}
                value={localCompany ? formData.localSelectedCompany : formData.selectedCompany}
                noOptionsText={
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={clickToRegister}
                    >
                        Click here to Register
                    </Button>
                }
                renderOption={(props, option) => (
                    <li {...props} key={option.companyId || option.companyname}>
                        <Avatar src={option.logo || "/placeholder.png"} alt="Logo" />
                        <Typography sx={{ ml: 2 }}>{option.companyname}</Typography>
                    </li>
                )}
                renderInput={(params) => <TextField {...params} label="Search Company" />}
            />
        </div>
    );
};

export default CompanySearchSelect;
