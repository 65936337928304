import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Button } from "@mui/material";
import '../styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faImage } from "@fortawesome/free-solid-svg-icons";

const LocalCompanyLogo = ({ prev, next }) => {
    const [logoPreview, setLogoPreview] = useState(localStorage.getItem('logoCompanyLogo') || null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');

    // Function to upload the image to ImgBB
    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        try {
            setUploading(true);
            const response = await axios.post('https://api.imgbb.com/1/upload?key=cc06fc73841e710dbc829cc7d12c5b38', formData);
            return response.data.data.url; // Return the image URL from the response
        } catch (error) {
            console.error('Error uploading image:', error);
            return null;
        } finally {
            setUploading(false);
        }
    };

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setError(''); // Clear any previous error
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                setLogoPreview(imageUrl);
                localStorage.setItem('logoCompanyLogo', imageUrl);
            }
        }
    };

    const handleRemoveImage = () => {
        setLogoPreview(null);
        localStorage.removeItem('logoCompanyLogo');
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 1
    });

    const handleNext = () => {
        if (!logoPreview) {
            setError('Logo is required. Please upload your company logo.');
            return;
        }
        next();
    };

    return (
        <div className="container mt-5 text-start fade-in">
            <div className="header">
                <h2>Add Your Local Office/Region Lofo</h2>
                <p>Please Drag / Drop or click to upload the logo</p>
            </div>

            <div className="row">
            <div className="form-group col-md-6 mt-3">
                    <label htmlFor="logo">
                        Company Logo <span className="text-danger">*</span>
                    </label>
                    <div {...getRootProps({
                        className: 'dropzone',
                        style: {border: '2px dashed #cccccc', padding: '50px', cursor: 'pointer', position: 'relative'}
                    })}>
                        <input {...getInputProps()} name="logo"/>
                        <p style={{textAlign: 'center', margin: '0'}}>
                            <FontAwesomeIcon icon={faImage}
                                             style={{fontSize: '48px', color: 'red', marginBottom: '10px'}}/>
                        </p>
                        <p style={{textAlign: 'center'}}>Drag 'n' drop a logo image here, or click to select one</p>
                    </div>
                    {uploading && <p>Uploading...</p>}
                    {error && <p className="text-danger mt-2">{error}</p>}
                </div>
                <div className="col-md-6 mt-3">
                    {logoPreview && (
                        <div className="mt-3">
                            <p>Your Selected Logo</p>
                            <img src={logoPreview} alt="Logo Preview" className="img-thumbnail"/>
                            <br/>
                            <button type="button" onClick={handleRemoveImage}
                                    className="btn btn-outline-danger mt-2">Remove
                                Image
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <hr/>
            <div className="d-flex justify-content-between mt-3">
                <Button
                    type="button"
                    onClick={prev}
                    className="prev-button btn-outline-secondary"
                    startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}>
                    Prev
                </Button>
                <Button
                    type="button"
                    onClick={handleNext}
                    className="next-button btn-danger"
                    disabled={uploading}
                    endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

export default LocalCompanyLogo;
