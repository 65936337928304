import React, {createContext, useContext, useState, useEffect} from 'react';

const FormContext = createContext();

export const FormProvider = ({children}) => {
    const getStoredData = () => {
        const storedData = localStorage.getItem('formData');
        const storedStep = localStorage.getItem('currentStep');
        return {
            formData: storedData ? JSON.parse(storedData) : {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                entityType: '',
                userType: '',
                industryType: '',

                corporationName: '',
                officeAddress: '',
                city:'',
                state:'',
                country:'',
                zipcode:'',
                phoneNumber: '',
                faxNumber: '',

                logo: null,
                companyId: '',
                selectedCompany: '',
                selectedCompanyEmail: '',
                selectedCompanyAddress: '',
                selectedCompanyID: '',
                selectedCompanyPhone: '',
                selectedCompanyType: '',
                localCompanyLogo: null,
                localSelectedCompany: '',
                localWhatWeOffer: '',
                localCorporationName: '',
                localOfficeAddress: '',
                localFormattedAddress : [],
                localPhoneNumber: '',
                localFaxNumber: '',
                localCity: '',
                localState: '',
                localCountry: '',

                whatWeOffer : [],
                childCompanies: [],
                marketingStaff :[],
                formattedAddress :[],
                numRepresentatives:0 ,
                numSalesStaff:0,
                salesTeams:[],
                state_code:'',
                regions:[],
                regions2:[],
                brokers:[],
                registeredCompanyId: '',
                registeredCompany:[],
            },
            currentStep: storedStep ? parseInt(storedStep) : 1,
        };
    };

    const {formData: initialFormData, currentStep: initialStep} = getStoredData();

    const [formData, setFormData] = useState(initialFormData);
    const [currentStep, setCurrentStep] = useState(initialStep);

    const hasStoredData = initialFormData && Object.values(initialFormData).some(value => value);

    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);

    useEffect(() => {
        localStorage.setItem('currentStep', currentStep);
    }, [currentStep]);

    const updateFormData = (newData) => {
        setFormData(prevData => ({...prevData, ...newData}));
    };

    const nextStep = (step) => {
        setCurrentStep((prevStep) => (step !== undefined ? step : prevStep + 1));
    };

    const prevStep = () => {
        setCurrentStep(prevStep => Math.max(prevStep - 1, 1));
    };

    const resetForm = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            entityType: '',
            userType: '',
            industryType: '',
            corporationName: '',
            officeAddress: '',
            phoneNumber: '',
            faxNumber: '',
            logo: null,
            companyId: '',
            selectedCompany: '',
            selectedCompanyEmail: '',
            selectedCompanyAddress: '',
            selectedCompanyID: '',
            selectedCompanyPhone: '',
            selectedCompanyType: '',
            whatWeOffer : [],
            state_code:'',
            childCompanies: [],
            marketingStaff: [],
            numRepresentatives:0,
            numSalesStaff:0,
            salesTeams:[],
            regions:[],
            brokers:[],
            formattedAddress :[],
            registeredCompanyId: '',
            registeredCompany:[],

        });
        setCurrentStep(1);
        localStorage.removeItem('companyLogo');
        localStorage.removeItem('formData');
        localStorage.removeItem('currentStep');
    };

    return (
        <FormContext.Provider
            value={{formData, updateFormData, currentStep, nextStep, prevStep, resetForm, hasStoredData}}>
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => useContext(FormContext);
