import React from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useFormContext } from "../context/FormContext";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faRemove} from "@fortawesome/free-solid-svg-icons";

function Invite8({ prev, next }) {
    const { formData, updateFormData } = useFormContext();

    const initialValues = {
        regions: formData.regions.map((region) => ({
            regionName: region.regionName,
            numBrokers: region.numBrokers,
            brokers: region.brokers && region.brokers.length > 0
                ? region.brokers.map((broker) => ({
                    brokerName: broker.brokerName || '',
                    brokerageName: broker.brokerageName || '',
                    email: broker.email || '',
                    numAssociates: broker.numAssociates || '',
                    numTeams: broker.numTeams || '',
                    agents: broker.agents && broker.agents.length > 0
                        ? broker.agents.map((agent) => ({
                            agentName: agent.agentName || '',
                            agentEmail: agent.agentEmail || '',
                        }))
                        : [],
                    teams: broker.teams && broker.teams.length > 0
                        ? broker.teams.map((team) => ({
                            teamName: team.teamName || '',
                            teamLeaderName: team.teamLeaderName || '',
                            email: team.email || '',
                            numAssociates: team.numAssociates || '',
                            teamMembers: team.teamMembers && team.teamMembers.length > 0
                                ? team.teamMembers.map((member) => ({
                                    memberName: member.memberName || '',
                                    memberEmail: member.memberEmail || '',
                                }))
                                : (team.numAssociates > 0
                                    ? Array.from({ length: parseInt(team.numAssociates, 10) }).map(() => ({
                                        memberName: '',
                                        memberEmail: '',
                                    }))
                                    : []),
                        }))
                        : broker.numTeams > 0
                            ? Array.from({ length: parseInt(broker.numTeams, 10) }).map(() => ({
                                teamName: '',
                                teamLeaderName: '',
                                email: '',
                                numAssociates: '',
                            }))
                            : [],
                }))
                : [],
        })),
    };


    const validationSchema = Yup.object({
        regions: Yup.array().of(
            Yup.object({
                regionName: Yup.string().required("Region name is required"),
                brokers: Yup.array().of(
                    Yup.object({
                        brokerName: Yup.string().required("Broker name is required"),
                        teams: Yup.array().of(
                            Yup.object({
                                teamName: Yup.string().required("Team name is required"),
                                teamLeaderName: Yup.string().required("Team leader name is required"),
                                email: Yup.string()
                                    .email("Invalid email format")
                                    .required("Email is required"),
                                numAssociates: Yup.number()
                                    .min(0, "Cannot be negative")
                                    .typeError("Must be a number")
                                    .required("Number of associates is required"),
                                teamMembers: Yup.array().of(
                                    Yup.object({
                                        memberName: Yup.string().required("Team member name is required"),
                                        memberEmail: Yup.string()
                                            .email("Invalid email format")
                                            .required("Team member email is required"),
                                    })
                                ),
                            })
                        ),
                    })
                ),
            })
        ),
    });

    return (
        <div className="container mt-5 text-start">
            <h2>Invite Team Members/Associates/Agents in each region.</h2>
            <p className="text-start">
                Please provide the details for Team Members belonging to the team
            </p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateFormData({ ...formData, ...values });
                    next();
                }}
            >
                {({ values }) => (
                    <Form className="w-100">
                        {values.regions.map((region, regionIndex) => (
                            <div key={regionIndex} className="card p-3 mb-4">
                                <h4>Region: {region.regionName}</h4>
                                {region.brokers.map((broker, brokerIndex) => (
                                    <div key={brokerIndex} className="card mb-3 p-3 bg-light">
                                        <h5>Broker: {broker.brokerName}</h5>
                                        {broker.teams.map((team, teamIndex) => (
                                            <div key={teamIndex} className="card p-3 mb-3">
                                                <h6>Team: {team.teamName}</h6>
                                                <FieldArray name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers`}>
                                                    {({ push, remove }) => (
                                                        <>
                                                            {team.teamMembers.map((member, memberIndex) => (
                                                                <div key={memberIndex} className="row mb-3">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label
                                                                                htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberName`}
                                                                            >
                                                                                Team Member Name
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                className="form-control"
                                                                                id={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberName`}
                                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberName`}
                                                                                placeholder="Enter team member name"
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberName`}
                                                                                component="div"
                                                                                className="text-danger"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label
                                                                                htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberEmail`}
                                                                            >
                                                                                Team Member Email
                                                                            </label>
                                                                            <Field
                                                                                type="email"
                                                                                className="form-control"
                                                                                id={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberEmail`}
                                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberEmail`}
                                                                                placeholder="Enter team member email"
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamMembers.${memberIndex}.memberEmail`}
                                                                                component="div"
                                                                                className="text-danger"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-end">
                                                                        <Button
                                                                            type="button"
                                                                            className="btn btn-danger"
                                                                            onClick={() => remove(memberIndex)}
                                                                        >
                                                                            Remove Member
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <Button
                                                                type="button"
                                                                onClick={() => push({ memberName: "", memberEmail: "" })}
                                                                className="btn btn-primary mb-3"
                                                            >
                                                                Add Team Member
                                                            </Button>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Invite8;
