import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';
import { Button, Modal, Typography, Box } from "@mui/material";
import axios from "axios";
import { API_BASE_URL } from "../config";

const Register = ({ prev, next }) => {
    const { formData, updateFormData } = useFormContext();
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state

    const fetchCompanies = async (email) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/get-user-company`, {
                params: { email, industryType: formData.industryType }
            });

            if (response.data && response.data.hasCompany) {
                let company = response.data.company;
                updateFormData({
                    ...formData,
                    registeredCompanyId: company.id ?? '',
                    selectedCompany: null,
                    corporationName: company.name,
                    officeAddress: `${company.address}, ${company.city}, ${company.state}, ${company.country}, ${company.zipcode}`,
                    city: company.city,
                    state: company.state,
                    country: company.country,
                    zipcode: company.zipcode,
                    state_code: company.state_code,
                    email: company.email,
                    phoneNumber: company.tel,
                    faxNumber: company.fax,
                    childCompanies: [],
                });
                localStorage.setItem('companyLogo', company.logoUrl);
                setShowPopup(true);
            }
            else{
                updateFormData({
                    ...formData,
                    registeredCompanyId:  '',
                });
            }
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        if (formData.email) {
            fetchCompanies(formData.email);
        }
    }, [formData.email]);

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
    });

    const [openModal, setOpenModal] = useState(false); // State for modal

    const handleSubmit = async (values) => {
        updateFormData(values);

        // If industryType is NOT "Senior Living" AND NOT "Senior Real Estate", submit the form
        if (formData.industryType !== "Senior Living" && formData.industryType !== "Senior Real Estate") {
            setLoading(true);
            try {
                await axios.post(`${API_BASE_URL}/save-user-company`, values);
                setOpenModal(true); // Open the modal on success
            } catch (error) {
                console.error("Error submitting form:", error);
                alert("Something went wrong. Please try again.");
            } finally {
                setLoading(false);
            }
        } else {
            next(); // Proceed to the next step in the form
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div className="container mt-5 mb-5 fade-in">
            <div className="header">
                <h2 className="text-start mb-4">Register Now</h2>
                <p className="text-start mb-4 pb-4">Please fill in the details below and begin your participation in the senior marketplace</p>
            </div>
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleChange, handleBlur, values }) => (
                    <Form className="w-100">
                        <div className="row text-start">
                            <div className="col-md-6 mb-4">
                                <div className="input-container">
                                    <div className="form-group">
                                        <label htmlFor="firstName">First Name <span
                                            className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder="Your First Name"
                                            className="form-control"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <ErrorMessage name="firstName" component="div" className="text-danger mt-2"/>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="input-container">
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last Name <span
                                            className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            placeholder="Your Last Name"
                                            className="form-control"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <ErrorMessage name="lastName" component="div" className="text-danger mt-2"/>
                                </div>
                            </div>

                            <div className="col-md-12 mb-4">
                                <div className="input-container">
                                    <div className="form-group">
                                        <label htmlFor="email">Email Address <span
                                            className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            placeholder="Complete Email Address"
                                            id="email"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                handleBlur(e);
                                                fetchCompanies(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <ErrorMessage name="email" component="div" className="text-danger mt-2"/>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="input-container">
                                    <div className="form-group">
                                        <label htmlFor="password">Password <span
                                            className="text-danger">*</span></label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={values.password}
                                            placeholder="Please Type 6 digit secure password"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <ErrorMessage name="password" component="div" className="text-danger mt-2"/>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4">
                                <div className="input-container">
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Confirm Password <span
                                            className="text-danger">*</span></label>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            value={values.confirmPassword}
                                            placeholder="Confirm Password"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <ErrorMessage name="confirmPassword" component="div" className="text-danger mt-2"/>
                                </div>
                            </div>
                        </div>
                        <hr style={{margin: '10px 0'}}/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>

                            {formData.industryType === "Senior Living" || formData.industryType === "Senior Real Estate" ? (
                                <Button
                                    type="submit"
                                    className="next-button btn-danger"
                                    endIcon={<FontAwesomeIcon icon={faArrowRight}/>}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    className="submit-button next-button btn-primary"
                                    disabled={loading}>
                                    {loading ? <FontAwesomeIcon icon={faSpinner} spin/> : "Submit"}
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        Information Saved
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Your information has been saved. We will update you once the industry has started.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleCloseModal}>
                        OK
                    </Button>
                </Box>
            </Modal>

        </div>

    );
};

export default Register;
