import logo from './logo.svg';
import './App.css';
import YstForm from "./components/YstForm";
import { FormProvider } from "./context/FormContext";
import React from "react";
import WelcomeBackPopup from "./components/WelcomeBackPopup";

const App = () => {
    return (
        <div className="App">
                <FormProvider>
                    <div className="d-flex align-items-start mt-4 ms-4"> {/* Align items to the start and add left margin */}
                        <img src={logo} alt="Logo" className="img-fluid" style={{ maxHeight: '50px' }} />
                    </div>
                    <hr className="mt-2 mb-4" /> {/* Add a horizontal line with margins */}
                    <YstForm />
                </FormProvider>
        </div>
    );
};

export default App;
