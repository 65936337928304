import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useFormContext } from '../context/FormContext';
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faRemove} from "@fortawesome/free-solid-svg-icons";

function Invite7({ prev, next }) {
    const { formData, updateFormData } = useFormContext();

    const initialValues = {
        regions: formData.regions.map((region) => ({
            regionName: region.regionName,
            numBrokers: region.numBrokers,
            brokers: region.brokers && region.brokers.length > 0
                ? region.brokers.map((broker) => ({
                    brokerName: broker.brokerName || '',
                    brokerageName: broker.brokerageName || '',
                    email: broker.email || '',
                    numAssociates: broker.numAssociates || '',
                    numTeams: broker.numTeams || '',
                    agents: broker.agents && broker.agents.length > 0
                        ? broker.agents.map((agent) => ({
                            agentName: agent.agentName || '',
                            agentEmail: agent.agentEmail || '',
                        }))
                        : [],
                    teams: broker.teams && broker.teams.length > 0
                        ? broker.teams.map((team) => ({
                            teamName: team.teamName || '',
                            teamLeaderName: team.teamLeaderName || '',
                            email: team.email || '',
                            numAssociates: team.numAssociates || '',
                            teamMembers: team.teamMembers && team.teamMembers.length > 0
                                ? team.teamMembers.map((member) => ({
                                    memberName: member.memberName || '',
                                    memberEmail: member.memberEmail || '',
                                }))
                                : (team.numAssociates > 0
                                    ? Array.from({ length: parseInt(team.numAssociates, 10) }).map(() => ({
                                        memberName: '',
                                        memberEmail: '',
                                    }))
                                    : []),
                        }))
                        : broker.numTeams > 0
                            ? Array.from({ length: parseInt(broker.numTeams, 10) }).map(() => ({
                                teamName: '',
                                teamLeaderName: '',
                                email: '',
                                numAssociates: '',
                            }))
                            : [],
                }))
                : [],
        })),
    };


    const validationSchema = Yup.object({
        regions: Yup.array().of(
            Yup.object({
                regionName: Yup.string().required('Region name is required'),
                brokers: Yup.array().of(
                    Yup.object({
                        brokerName: Yup.string().required('Broker name is required'),
                        brokerageName: Yup.string().required('Brokerage name is required'),
                        email: Yup.string()
                            .email('Invalid email format')
                            .required('Email is required'),
                        numAssociates: Yup.number()
                            .required('Number of associates is required')
                            .min(0, 'Cannot be negative')
                            .typeError('Must be a number'),

                        teams: Yup.array().of(
                            Yup.object({
                                teamName: Yup.string().required('Team name is required'),
                                teamLeaderName: Yup.string().required('Team Leader name is required'),
                                email: Yup.string()
                                    .email('Invalid email format')
                                    .required('Email is required'),
                                numAssociates: Yup.number()
                                    .required('Number of team members is required')
                                    .min(0, 'Cannot be negative')
                                    .typeError('Must be a number'),
                            })
                        ),
                    })
                ),
            })
        ),
    });

    return (
        <div className="container mt-5 text-start">
            <h2>Set Up Teams & Team Leaders for Brokerages</h2>
            <p className="text-start">
                Set Up Brokerage's Teams and Number of Team Members in each. These can be added and will be assigned to the Team they belong within the brokerage/firm.</p>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    updateFormData({ ...formData, regions: values.regions });
                    next();
                }}
            >
                {({ values }) => (
                    <Form className="w-100">
                        {values.regions.map((region, regionIndex) => (
                            <div key={regionIndex} className="card p-3 mb-4 text-left">
                                <h4>{region.regionName}</h4>
                                <hr className="mt-1 mb-2" />
                                {region.brokers.map((broker, brokerIndex) => (
                                    <div key={brokerIndex} className="mb-3">
                                        <h5>Broker: {broker.brokerName}</h5>
                                        <FieldArray name={`regions.${regionIndex}.brokers.${brokerIndex}.teams`}>
                                            {() => (
                                                <>
                                                    {broker.teams.map((team, teamIndex) => (
                                                        <div key={teamIndex} className="row mb-3 text-left">
                                                            <div className="col-md-3 text-left">
                                                                <div className="form-group text-start">
                                                                    <label
                                                                        htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamName`}>
                                                                        Team Name
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamName`}
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamName`}
                                                                        placeholder="Enter Team name"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamName`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 text-left">
                                                                <div className="form-group text-start">
                                                                    <label
                                                                        htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamLeaderName`}>
                                                                        Team Leader Name
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamLeaderName`}
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamLeaderName`}
                                                                        placeholder="Enter Team Leader name"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.teamLeaderName`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.email`}>
                                                                        Email
                                                                    </label>
                                                                    <Field
                                                                        type="email"
                                                                        className="form-control"
                                                                        id={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.email`}
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.email`}
                                                                        placeholder="Enter email"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.email`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.numAssociates`}>
                                                                        Num Team Members
                                                                    </label>
                                                                    <Field
                                                                        type="number"
                                                                        className="form-control"
                                                                        id={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.numAssociates`}
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.numAssociates`}
                                                                        placeholder="0"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`regions.${regionIndex}.brokers.${brokerIndex}.teams.${teamIndex}.numAssociates`}
                                                                        component="div"
                                                                        className="text-danger"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                ))}
                            </div>
                        ))}
                        <hr/>
                        <div className="d-flex justify-content-between">
                            <Button
                                type="button"
                                onClick={prev}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            >
                                Prev
                            </Button>
                            <Button
                                type="button"
                                onClick={next}
                                className="prev-button btn-outline-secondary"
                                startIcon={<FontAwesomeIcon icon={faRemove} />}>
                                Skip
                            </Button>
                            <Button
                                type="submit"
                                className="next-button btn-danger"
                                endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                            >
                                Next
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    );
}

export default Invite7;
